const SocialLink = {
  Telegram: "http://t.me/dimitri_posadskiy",
  Linkedin: "https://linkedin.com/in/dimitri-posadskiy/",
  GitHub: "https://github.com/posadskiy",
  Messenger: "https://m.me/dmitry.posadsky",
  Instagram: "https://instagram.com/dimitri.posadskiy",
}

export {
  SocialLink,
};
