import React, {Component} from 'react';
import Routes from "../Routes";

class Content extends Component {
	render() {
		return (
			<div style={{flex: 19, display: "flex"}}>
				<Routes/>
			</div>
		)
	}
}

export default Content;
